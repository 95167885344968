import { render, staticRenderFns } from "./arrow-chart.svg?vue&type=template&id=1fd8f7a0&functional=true&icon-component"
var script = {}


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  null,
  null
  
)

export default component.exports